/* Variables */
$sm_primary: #ffa900;
$sm_secondary: #dadada;
$sm_danger: #eb445a;
$sm_success: #7ed957;
$sm_excellent: #7ed957;
$sm_good: #c9e265;
$sm_average: #ffde59;
$sm_bad: #ff914d;
$sm_poor: #ff5757;
$sm_information: #6B91FD;
$sm_header: #1f1f1f;
/* General */
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;

  :focus {
    outline: none !important;
  }

  .page-title-lg {
    text-align: center;
  }

  .page-title-sm {
    text-align: left;
  }

  .item-label {
    --min-height: unset;
  }

  .qr-code canvas {
    max-width: 100%;
  }

  .alert-tappable.alert-radio {
    height: auto;
    contain: content;
  }

  .alert-radio-label.sc-ion-alert-md,
  .alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
  }

  .field {
    border: 0;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    background: white;
    border-radius: 3px;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.75);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 999;
    -webkit-appearance: none;
  }

  .field:focus {
    outline: none;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.75);
    border-radius: 3px;
  }

  .align-text-right {
    text-align: right;
  }

  .ios-padding-bottom {
    padding-bottom: 40px;
  }

  .no-title-height {
    height: 0;
    padding: 0;
    margin: 0;
  }

  .normal-colour {
    color: #000;
  }

  .h4 {
    font-size: 20px;
  }

  .h2 {
    font-size: 24px;
  }

  .vertical-align {
    display: flex !important;
    align-items: center !important;
  }

  .justify-space-between {
    justify-content: space-between !important;
  }

  .social-share-icons {
    ion-icon {
      color: #FFF;
    }

    .facebook {
      background-color: #1877f2;
    }

    .twitter {
      background-color: #1da1f2;
    }

    .whatsapp {
      background-color: #00e676;
    }

    .linkedin {
      background-color: #0966c2;
    }

    .mail {
      background-color: #333333;
    }
  }

  .main-container {
    padding: 10px;
  }

  p {
    line-height: 1.5
  }

  .image-modal {
    --background: none;
    padding: 50% 10px 10px 10px;
  }

  ion-content {
    --background: #f5f5f5;
  }

  ion-card {
    border-radius: 6px;
    box-shadow: none;
    border: 1px solid $sm_secondary;

    ion-item {
      --border-color: #ededed;

      &:last-child {
        --border-color: #FFF;
      }
    }
  }

  .no-connection {
    width: calc(100% - 10px);
    position: absolute;
    top: 15%;
    z-index: 9999;
    --background: #000;
    --color: #FFF !important;
    padding: 10px;
    margin: 5px;
  }

  .desktop-details-toolbar {
    --color: white;
    --background: black;
  }

  .facebook-color-button {
    --background: #3B5998;
  }

  .button.info {
    padding: 3px;

    ion-icon {
      margin-right: 2px;
    }
  }

  .item-label.has-error,
  .item-input.has-error {
    --background: #f7eeee;
    color: darkred;
  }

  .welcome-images {
    height: 300px;
    width: auto;
  }

  ion-slides {
    height: 100% !important;
  }

  .clear-toolbar-bg {
    --background: none;
  }

  .sc-ion-modal-md-h:first-of-type {
    --backdrop-opacity: 0.7;
  }

  .bg-img {
    background-size: cover !important;
  }

  .distance-popover {
    .popover-content {
      top: 190px !important;
      left: calc(20px + var(--ion-safe-area-left, 0px));
      transform-origin: left top;
      width: 97%;
    }
  }

  .area-toggle {
    border-radius: 0;
    background-color: #f4f5f8;
    border-top: 1px solid $sm_secondary;
    border-bottom: 1px solid $sm_secondary;

    ion-segment-button {
      margin: 10px;
      --padding: 0;
    }
  }

  ion-card {
    margin: 0 10px 10px;
  }

  #submitted_by {
    text-transform: capitalize;
  }

  p {
    white-space: normal;
  }

  .card-title {
    font-size: 22px !important;
    font-weight: 700;
    color: $sm_header !important;
    padding: 5px 0;

    &.small-title {
      font-size: 18px !important;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  ion-chip.distance {
    background: rgba(0, 0, 0, 0.4);
    color: #FFF;
    font-weight: 700;
  }

  .socials {
    ion-icon {
      font-size: 24px;
      margin-right: 5px;
    }
  }

  .accordion {
    .accordion__item {
      .accordion__heading {
        .accordion__button {
          background-color: #f4f4f4;
          color: #444;
          cursor: pointer;
          padding: 18px 0 18px 18px;
          width: 100%;
          text-align: left;
          border: none;
          z-index: -1 !important;
        }

        .accordion__button:before {
          display: none;
        }

        .accordion__button:after {
          display: none;
        }
      }
    }
  }

  .accordion__button_z {
    z-index: -1 !important;
  }

  .yesnos-button {
    border-radius: .25rem;
  }

  .extra-icons {
    .item {
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      padding: 2.5px 5px;
      background-color: #f4f5f8;
      color: #000;

      ion-icon {
        height: 18px;
        width: 18px;
      }
    }
  }

  hr {
    width: 100%;
    border-top: 1px solid #ededed;
  }

  .floating-nav {
    position: absolute;
    top: 10px;
    left: 5px;
    color: #000000;
    background-color: #ffa900;
    border-radius: 50%;
  }

  .sm-title, .sm-title p {
    font-size: 20px;
    font-weight: 600;
    color: $sm_header;
    line-height: 22px;
  }

  .light-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.35;
    color: #1f1f1f;
  }

  .sm-title.light {
    color: #FFF;
  }

  ion-progress-bar {
    --buffer-background: #e6e6e6;
  }

  ion-card-title {
    color: $sm_header;
  }

  .answer-accordion {
    .answer-item {
      padding: 3px 5px 5px;
      border-bottom: 1px solid #ededed;

      &:last-child {
        border-bottom: 0;
      }

      ion-label {
        strong {
          color: $sm_header;
          font-size: 15px;
          font-weight: 600;
        }
      }

      .option-breakdown {
        width: 100%;
        padding-bottom: 2px;

        ion-progress-bar {
          border-left: 3px solid #ffaa01;
        }
      }

      .chevron-container.small {
        margin-top: 2px;
      }

      .item {
        --inner-padding-end: 0px;
        --border-color: #ededed;

        ion-progress-bar {
          height: 10px;
          border-radius: 2px;
        }

        &:last-child {
          --border-color: #ffffff;
        }
      }

      p {
        color: #1f1f1f;
        font-size: 13px;
      }
    }
  }

  .inline-header {
    .map {
      height: 170px;
    }

    .location-details {
      min-height: 100px;
      padding: 10px 15px;
      background-color: #FFF;
      width: auto;
      margin: -60px 10px 0;
      border-radius: 6px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;

      h1 {
        width: 80%;

        a {
          text-decoration: none;
        }
      }

      p.address {
        color: #555;
      }

      .inner {
        position: relative;
        width: 100%;

        ion-icon.share {
          position: absolute;
          right: 10px;
          top: 0;
          font-size: 26px;
        }
      }
    }

    .distance {
      position: absolute;
      right: 0;
      opacity: 1;
      margin: 5px 10px;
    }
  }

  #opening-times {
    .day {
      color: #1f1f1f;
      font-size: 15px;
      font-weight: 600;
      min-width: 95px;
    }
  }

  .search-box {
    overflow: visible;
    z-index: 999;
  }

  .filter-card {
    .filter-type {
      margin-top: 3px !important;

      .miles-distance {
        color: $sm_primary;
      }
    }

    ion-range {
      padding: 0 5px;
    }

    .sort-toggles {
      margin: 0 5px;

      ion-segment {
        padding: 5px;

        ion-segment-button {
          padding: 5px 0;
        }
      }
    }

    .search-container {
      margin: 0 5px;

      input {
        box-shadow: none;
        border: 2px solid $sm_primary;
      }

      .suggestions {
        list-style-type: none !important;

        .suggestion-list {
          background-color: #FFF;
          border: 2px solid $sm_primary;
          position: absolute;
          left: -1px;
          width: calc(100% + 2px);
          list-style-type: none !important;
          padding: 0;
          margin: 7px 0 0 0;
          height: calc(100vh - 115px) !important;
          overflow-y: scroll;
          z-index: 99999;

          .suggestion-item {
            list-style-type: none !important;
            width: 100%;
            //padding:10px 5px 5px 5px;
            border-bottom: 1px solid #eaeaea;

            .suggestion {
              list-style-type: none !important;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .dashed_underline {
    border-bottom: 1px #000 dashed;
    //color: #000;
    font-weight: 700;
  }

  .modal-wrapper .ion-page {
    display: block;
  }

  .search-loader {
    position: absolute;
    z-index: 999;
    right: 30px;
  }

  .fixed-position-header {
    position: fixed;
    z-index: 9999;
  }

  .box-margin-top {
    margin-top: 20px;
  }

  .container {
    max-width: 1250px;
    margin: auto;
  }

  .container-2 {
    max-width: 1250px;
    margin: auto;
    background-color:#fff;
  }

  .full-chip {
    width: 100%;
    text-align: left;
  }

  .left-edit-button {
    margin-left: auto;
  }

  .header-ios {
    visibility: visible;
  }

  .score-success {
    .success-message p {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .breadcrumbs {
    .crumb {
      margin-right: 7px;
      display: flex;
      align-items: center;

      a {
        display: inline-flex;
        margin-right: 7px;
      }

      &:after {
        content: "\203A";
      }

      &:last-of-type {
        margin-right: 0;

        &:after {
          content: "";
        }
      }
    }
  }

  .auth-form {
    max-width: 625px;
    margin: 20px;

    .item-input {
      border: 1px solid #cccccc;
      border-radius: 5px;
    }
  }
}

/* Posts index */
.posts {
  .featured-img {
    background-size: cover !important;
    min-height: 200px;
  }
}

/* Desktop Header */
.header {
  .desktop {
    ion-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 75px;
    }

    .nav-items {
      ion-button {
        margin: 3px 10px 0;
        border-bottom: 3px solid transparent;

        &:hover {
          border-bottom: 3px solid #ffa900;
        }

        ion-label {
          color: #1f1f1f;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}

/* Margins */
.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

/* Footer */
.footer-fix {
  position: fixed;
  bottom: 0px;
}

#web-lg-footer {
  background-color: $sm_primary;
  padding: 40px 0;
  height: 290px;

  .footer-logo {
    width: 300px;
  }

  .footer-menu {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 5px;

      a {
        color: #000;
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}

/* Welcome slides */
.welcome-slides {
  ion-slide {
    background-size: cover !important;
  }

  ion-footer {
    background-color: #eaeaea;
    border-top: 3px solid $sm_primary;

    ion-button {
      --color: #1f1f1f;
    }
  }

  .swiper-pagination {
    top: 20px;

    .swiper-pagination-bullet {
      background: #444444;
    }
  }
}

/* Tab bar */
.tab-bar-height {
  --background: #eaeaea;
  border-top: 3px solid $sm_primary;
  height: 45px !important;

  ion-tab-button {
    --color: #a3a3a3;
    --color-selected: #393939;
  }
}

/* Web views */
.desktop-home {
  background-size: cover !important;
  padding: 40px;
  color: #FFF;

  .vertical-align {
    justify-content: center;
  }

  .intro-text {
    text-align: center;

    h1 {
      font-size: 4rem;
      font-weight: 700;
    }

    h2 {
      font-size: 1.85rem;
      font-weight: 600;
    }

    p {
      margin: 18px 0 10px;
      color: $sm_primary;
      font-size: 1.75rem;
    }

    @media only screen and (max-width: 599px) {

      h1 {
        font-size: 1rem;
      }

      h2 {
        font-size: 1rem;
      }

      p {
        margin: 10px 0 10px;
        font-size: 1rem;
      }
    }

    .highlight {
      color: $sm_primary;
    }
  }
}

.blend-right {
  position: absolute;
  display: table;
  text-align: center;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(to left, rgba(245, 245, 245, 0), rgba(245, 245, 245, 1));
  z-index: 9999;
}

.blend-left {
  position: absolute;
  display: table;
  text-align: center;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(245, 245, 245, 0), rgba(245, 245, 245, 1));
  z-index: 9999;
}

.arrow-center {
  display: table-cell;
  vertical-align: middle;
}

.desktop-latest-teaser {
  background-color: $sm_primary;
}

.desktop-offers-teaser {
  background-color: #000;
}

.desktop-featured-img {
  background-size: cover !important;
  min-height: 200px;
}

.header {
  .logo {
    max-width: 300px;
  }

  .sc-ion-searchbar-ios-h {
    padding-bottom: 0;
    max-width: 400px;
    margin: 0 auto
  }

  //.sc-ion-buttons-ios-h {justify-content: flex-end;}
}

/* Buttons */
.action {
  border-radius: 6px;
  border: 0;
  font-weight: 700 !important;
  letter-spacing: -.5px;
  height: 38px;
  text-align: center;
  padding: 5px 12px;
  margin: 0;
}

.action.primary {
  background-color: $sm_primary;
  --background: $sm_primary;
  --background-activated: darken($sm_primary, 10%);
  --background-focused: darken($sm_primary, 10%);
  --background-hover: darken($sm_primary, 10%);
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --color: #000;
}

.action.secondary {
  background-color: $sm_secondary;
  --background: $sm_secondary;
  --background-activated: darken($sm_secondary, 10%);
  --background-focused: darken($sm_secondary, 10%);
  --background-hover: darken($sm_secondary, 10%);
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  color: #000;
}

.action.danger {
  background-color: $sm_danger;
  --background: $sm_danger;
  --background-activated: darken($sm_danger, 10%);
  --background-focused: darken($sm_danger, 10%);
  --background-hover: darken($sm_danger, 10%);
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  color: #FFF;
}

.action.success {
  background-color: $sm_success;
  --background: $sm_success;
  --background-activated: darken($sm_success, 10%);
  --background-focused: darken($sm_success, 10%);
  --background-hover: darken($sm_success, 10%);
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  color: #FFF;
}

.action.info {
  background-color: $sm_information;
  --background: $sm_information;
  --background-activated: darken($sm_information, 10%);
  --background-focused: darken($sm_information, 10%);
  --background-hover: darken($sm_information, 10%);
  --background-activated-opacity: 1;
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  color: #FFF;
}

.action.small {
  font-size: 14px;
  height: 27px;
  font-weight: 600;

  a {
    padding: 0;
  }
}

/* Header */
.header {
  --color: #000;
  font-weight: bold;
  //background: $sm_primary;
  //position:fixed;
  //top:0;
}

.header.search {
  background-size: cover !important;
  padding: 20px 10px;
  border-bottom: 1px solid $sm_secondary;

  .search-container {
    list-style-type: none !important;
    position: relative;
    margin: 20px 0;
    border-radius: 3px;
    -webkit-appearance: none;
    z-index: 9999;
  }

  .filter-options-breakdown {
    ion-chip {
      height: auto;
      border-radius: 6px;
      background: rgba(255, 255, 255, 0.8);
      margin: 0;

      table td {
        padding-right: 6px;
      }

      ion-icon {
        font-size: 50px;
        color: $sm_information;
        font-weight: 700;
      }
    }
  }

  .search-roundness {
    --border-radius: .25rem !important;
  }

  .place-search {
    position: relative;
    margin: 5px 0;

    input {
      padding-right: 0;
    }
  }

  .filter-options-container {
    ion-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .filter-buttons-sort {
        ion-select::part(icon) {
          display: none !important;
        }
      }
    }
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new-search {
  background: white;
  border: 1px solid #dadada;
  padding: 15px;
  border-radius: .25rem;

  .search-container {
    list-style-type: none !important;
    position: relative;
    margin: 20px 0;
    border-radius: 3px;
    -webkit-appearance: none;
    z-index: 9999;
  }

  ion-img {
    width: 90%;
    margin: auto;
  }

  .suggestions {
    list-style-type: none !important;

    .suggestion-list {
      position: absolute;
      left: 0;
      width: 100%;
      list-style-type: none !important;
      padding: 0;
      margin: 7px 0 0 0;
      height: calc(100vh - 115px) !important;
      overflow-y: scroll;
      z-index: 999999;

      .suggestion-item {
        list-style-type: none !important;
        width: 100%;
        background-color: #fff;
        padding: 10px 5px 5px 5px;

        .suggestion {
          list-style-type: none !important;
          text-decoration: none;

          .suggestion-title {
            font-weight: bold;
            font-size: 18px;
            color: #000;
          }

          .suggestion-address {
            font-weight: bold;
            font-size: 14px;
            color: #555;
          }

          .suggestion-distance {
            font-weight: bold;
            font-size: 10px;
          }
        }
      }
    }
  }
}

/* Submission */
.begin {
  p.intro {
    color: $sm_header;
    font-size: 18px;
  }

  .details {
    text-align: left;
    width: 80%;

    .sm-title {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .map {
    height: 100px;

    .distance {
      position: absolute;
      right: 0;
    }
  }

  ion-slides {
    height: 100%;
  }

  ion-slide {
    display: block;
  }

  ion-card-header {
    padding: 5px;
  }
}

.questionIcon {
  font-size: 32px;
  z-index: -1;
}

.questionLabel {
  z-index: -1;
}

/* Question interface */
.score-wrapper {
  .sidebar {
    display: none;
  }

  .main-container.score {
    ion-progress-bar {
      --progress-background: #7ed957;
      height: 6px;
    }

    #question-slider {
      .block-slide {
        display: block;
      }

      .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
        white-space: normal;
      }

      .file-upload {
        padding-bottom: 10px;
      }

      .question-slide {
        .answers {
          display: block;

          .question-answer {
            border: 1px solid #dedede;
            border-radius: .25rem;
            --border-width: 0;
            --inner-border-width: 0;
            height: auto;
            --min-height: unset;
            margin-bottom: 7px;
            position: relative;

            ion-icon {
              position: absolute;
              margin: 0 !important;
            }

            ion-label {
              text-align: center;
              font-size: 14px;
              margin: 5px 17px 5px 42px;
            }

            ion-radio {
              margin-right: 0;
            }
          }

          .question-answer.last {
            margin-bottom: 0;
          }
        }

        .add-comment {
          color: $sm_primary;
          text-decoration: underline;
          font-size: 14px;

          &:hover {
            cursor: pointer;
          }
        }

        .question-comment-input {
          padding: 10px;
          border: 1px solid #e0e0e0;
          font-weight: 700;
          text-align: left;

          textarea {
            padding: 0;
          }
        }

        .nav-btns ion-button {
          width: 100%;
        }
      }
    }
  }
}

.distance {
  padding: 0 5px;
  font-size: 11px;
  border-radius: .25rem;
  height: 22px;

  ion-icon {
    margin: 1px;
    font-size: 12px;
  }
}

/* Recent Submissions component */
.recent-subs {
  .item a {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $sm_header;
    padding: 10px 0;

    ion-label {
      flex-grow: 1;
    }
  }
}

/* Search Results */
.recent-searches {
  padding: 5px;

  ion-chip {
    margin-left: 0;
  }
}

.location-item {
  ion-card-title {
    font-size: 14px;
    margin-bottom: 5px;
  }

  ion-card-content {
    padding: 0;
  }

  ion-button.action {
    padding: unset;
    margin-top: 10px;
    height: 22px;
  }

  .inner {
    display: flex;
    flex-grow: 1;

    .map {
      width: 33%;
      justify-content: center;
      display: inline-block;
    }

    .content {
      width: 67%;
      display: inline-block;
      padding: 5px;
      font-size: 12px;
      color: #555;
      font-weight: 300;
      line-height: 1.35;

      .chevron-container {
        margin: 5px 0;
      }

      .score-arrow {
        width: 50px;
        display: inline-block;
      }
    }
  }
}

/* Single Location */
.single-location {
  .breadcrumbs {
    padding: 10px 20px 0;
  }

  #details {
    p {
      color: $sm_header;
      font-size: 13px;
      font-weight: 600;
    }

    .no-score {
      h2 {
        color: #555;
        font-weight: 300;
        margin-bottom: 10px;
      }

      p {
        color: #555;
        font-weight: 300;
        line-height: 1.6;
        margin: 0;
        padding: 0 10px;
        font-size: 14px;
        text-align: center;
      }
    }

    .extra-icons {
      .item {
        ion-icon {
          font-size: 27px;
        }
      }
    }
  }

  .action-card {
    .action {
      margin: 0 5px 5px 0;
    }
  }

  .extra-indicators {
    ion-card-content {
      padding: 5px 10px;

      ion-label {
        color: $sm_header;
        font-size: 15px;
        font-weight: 600;
      }

      ion-icon {
        margin: 0 10px 0 5px;
        font-size: 26px;
      }
    }

    ion-item {
      --min-height: unset;
    }
  }
}

.accordion__button {
  background-color: transparent;
  color: #92949d;
  cursor: pointer;
  padding: 0;
  width: auto;
  margin-left: auto;
}

/* Single Score */
.single-score {
  .score-card {
    ion-label {
      justify-content: space-between;
    }
  }

  .pack-navigation {
    display: flex;

    ion-item {
      font-size: 14px;
      padding: 0 0 0 10px;

      ion-label {
        margin: 0;
        white-space: normal;
      }
    }
  }

  .pack-answers {
    .answer-item {
      ion-label {
        white-space: normal;
      }

      .score {
        .inner {
          justify-content: center;
          height: 35px;
          width: 35px;

          ion-icon {
            font-size: 27px;
          }
        }

        &.rating-0 {
          background-color: $sm_poor;
        }

        &.rating-1 {
          background-color: $sm_bad;
        }

        &.rating-2 {
          background-color: $sm_average;
        }

        &.rating-3 {
          background-color: $sm_good;
        }

        &.rating-4 {
          background-color: $sm_excellent;
        }

        &.rating-5 {
          background-color: $sm_excellent;
        }

        &.yesno-3 {
          color: $sm_excellent;
          font-size: 24px;
        }

        &.yesno-2 {
          color: $sm_poor;
          font-size: 24px;
        }
      }
    }

    .answer-item.yesno {
      h2 {
        color: #1f1f1f;
        font-size: 15px;
        font-weight: 600;
      }

      p {
        color: #1f1f1f;
        font-size: 13px;
      }
    }
  }
}

/* Location details */
.single-location {
  #details {
    border: 3px solid #ffaa01;

    h1 {
      margin: 8px 0 0;
      text-align: center;
      color: #ffaa01;
      font-weight: 700;
      padding-bottom: 5px;
      border-bottom: 1px solid #ededed;
    }

    .sub-count {
      color: #5d5d5d;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

/* Extras */
.extra-tiles {
  ion-button {
    white-space: normal;
    height: 103px;

    ion-icon {
      font-size: 28px;
    }
  }
}

/*  Chevrons */
// insert the svg code in a variable, make sure the svg only contains double quotes and surround the whole code with simple quotes
$svg-icon-code: '<svg id="chevron" xmlns="http://www.w3.org/2000/svg" width="130" height="70" viewBox="0 0 130 70"><polygon points="95.66 0.13 95.66 0 95.53 0 40.19 0 0 0 0 0.07 20.06 20.4 34.48 35.01 20.14 49.54 0 69.94 0 70 40.33 70 95.39 70 95.66 70 95.66 69.73 130 34.93 95.66 0.13" fill="rgb(255,0,0)"/></svg>';
/* variables */
$width: 65px;
$height: 30px;
$gap: 3px; //Space between each chevron
$zoom: 1.3; //Zoom factor of the bigger chevron
/* colours */
$chevron-red: rgb(252, 89, 91);
$chevron-green: rgb(129, 215, 94);
$chevron-lime: rgb(201, 224, 109);
$chevron-yellow: rgb(254, 221, 100);
$chevron-orange: rgb(253, 145, 85);
$chevron-grey: rgb(230, 230, 230);
/* Function to calculate the negative margin between items, allows for scaling to give an equal margin on any size*/
/* The arrow part is 26% of the overall width which is the part than needs to overlap */
@function margin($width) {
  @return (($width*0.26*-1)+$gap)
}

/* ------------------------------------------------- */
/*  create url encoded string to allow background-image url to work */
/*  from https://codepen.io/jakob-e/pen/doMoML      */
/* ------------------------------------------------- */
@function svg-url($svg) {
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode (may need a few extra replacements)
    //
    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index +
            str-length($search)), $search, $replace);
  }
  @return $string;
}

/* custom mixin to replace a defined fill color with another one */
@function svg-url-with-replaced-fill($svg, $fill-to-replace, $new-fill) {
  $replaced-svg: str-replace($svg, $fill-to-replace, $new-fill);
  $replaced-svg-url: svg-url('#{$replaced-svg}');
  @return $replaced-svg-url;
}

/* custom CSS using mixin */
.chevron-green {
  background-image: svg-url-with-replaced-fill($svg-icon-code, 'rgb(255,0,0)', $chevron-green);
}

.chevron-lime {
  background-image: svg-url-with-replaced-fill($svg-icon-code, 'rgb(255,0,0)', $chevron-lime);
}

.chevron-yellow {
  background-image: svg-url-with-replaced-fill($svg-icon-code, 'rgb(255,0,0)', $chevron-yellow);
}

.chevron-orange {
  background-image: svg-url-with-replaced-fill($svg-icon-code, 'rgb(255,0,0)', $chevron-orange);
}

.chevron-red {
  background-image: svg-url-with-replaced-fill($svg-icon-code, 'rgb(255,0,0)', $chevron-red);
}

.chevron-grey {
  background-image: svg-url-with-replaced-fill($svg-icon-code, 'rgb(255,0,0)', $chevron-grey);
}

.chevron-container.small {
  margin: 0;

  .chevron {
    width: $width*0.5;
    height: $height*0.5;
    display: inline-flex;

    span {
      font-size: 14px;
    }
  }

  > .chevron + .chevron {
    margin-left: margin($width*0.5)
  }

  > .chevron + .chevron.zoom {
    margin-left: margin(($width*0.5))
  }

  > .chevron.zoom.rotated {
    margin-left: margin($width*0.5)
  }

  > .chevron.zoom.rotated + .chevron {
    margin-left: margin(($width*0.5)*$zoom)
  }
}

.chevron-container {
  display: flex;
  align-self: center;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  max-width: 100%;
}

.chevron {
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: $width;
  height: $height;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-top: 1px;

  &:first-child {
    padding-left: 0;
  }
}

.chevron-small {
  width: $width*0.8;
  height: $height*0.8;
  display: inline-flex;

  span {
    font-size: 16px;
  }
}

/* don't apply left margin to first item */
.chevron-container > .chevron + .chevron {
  margin-left: margin($width)
}

/* bigger negative margin for the zoomed version */
.chevron-container > .chevron + .chevron.zoom {
  margin-left: margin($width*$zoom)
}

/* switch the negative margin on the zoomed+rotated version*/
.chevron-container > .chevron.zoom.rotated {
  margin-left: margin($width)
}

.chevron-container > .chevron.zoom.rotated + .chevron {
  margin-left: margin($width*$zoom)
}

.chevron span {
  font-size: 1.5rem;
  font-family: Arial;
  font-weight: bold;
  color: white;
}

.rotated {
  transform: rotate(180deg);
}

.chevron.rotated span {
  transform: rotate(180deg);
}

.chevron.zoom {
  width: $width*$zoom;
  height: $height*$zoom;
}

/* Desktop and up */
@media only screen and (max-width: 1200px) {
  ion-header .desktop {
    ion-buttons {
      flex-direction: column;

      .nav-items {
        order: 2;
      }

      .auth-links {
        order: 1;
        margin-left: auto;
        border: 0;
      }
    }
  }
}

@media only screen and (max-width: 1800px) {
  .intro-text {
    position: relative;
    left: 16%;
    width: 60%;
    text-align: left!important;
  }
}

@media only screen and (max-width: 1000px) {
  .header .logo {
    max-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .close-icon {
    position:absolute;
    top:10px;
    right: 6px;
    font-size: 30px;
    margin-bottom: 20px;
    z-index:99999;
  }

  .progress-text{
    position:absolute;
    top:15px;
    left: 10px;
    font-size: 18px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) {
  body ion-card {
    margin: 0 0 10px 0;
  }
  body {
    .auth-form {
      margin: 0 auto 20px;
    }

    .auth-links {
      padding-left: 15px;
      border-left: 2px solid #dadada;

      .action {
        height: unset !important;

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }

    .header.search {
      padding: 0;

      ion-toolbar {
        margin: auto;
        --border-width: 0 !important;
        --padding-start: unset;
        --padding-end: unset;
      }

      .place-search {
        margin: 0 auto 0 0;
        height: 65px;
        background-color: #FFF;
        min-width: 100%;
        display: inline-block;
        border-radius: .25rem !important;

        .searchbar-input-container {
          height: 60px;

          .searchbar-input {
            font-size: 24px;
            font-weight: 600;
            padding-left: 35px;
          }
        }
      }

      .filter-options-breakdown {
        text-align: center;
        width: 100%;
        display: inline-block;
        float: right;

        ion-chip ion-icon {
          font-size: 28px;
        }
      }
    }

    .location-item {
      margin: 0;

      .inner {
        .content {
          padding: 15px;
          font-size: unset;
          width: 50%;

          ion-card-title {
            font-size: 22px;
          }

          ion-button.action {
            height: 38px;
            font-size: 15px;
          }

          .chevron-container {
            margin: 15px 0;
          }
        }

        .map {
          width: 50%;
        }
      }
    }

    .extra-icons {
      .item {
        padding: 5px 10px;
        font-size: 15px;
      }
    }

    .inline-header {
      .location-details {
        margin: 0 !important;
      }
    }

    .main-container.score .inner {
      max-width: 750px;
      margin: auto;
    }

    .score-wrapper {
      display: flex;
      height: 100%;

      .sidebar {
        display: inline-block;
        width: 25%;
        height: 100%;
        padding: 40px;
        background-color: #ffa900;
      }

      .score {
        display: inline-block;
        width: 75%;
        height: 100%;
        padding: 40px;

        #question-slider .question-slide .answers .question-answer {
          background-color: #FFF;
          margin-bottom: 15px !important;
          display: flex;
          align-items: center;

          ion-label {
            font-size: unset !important;
          }
        }
      }
    }

    .single-score {
      .chevron-container {
        margin: 0;
      }
    }

    .main-container.score {
      ion-header {
        display: none;
      }

      .close-icon {
        font-size: 30px;
        float: right;
        margin-bottom: 20px;
        z-index:99999;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}